import React from 'react';
import styled from 'styled-components';
import { HiArrowRight } from "react-icons/hi";
import {useTranslation} from "react-i18next";
interface FeatureProps {
    title: string;
    description: string;
    image: string;
    imagePosition: 'left' | 'right';
}

interface StyledFeatureContainerProps {
    imagePosition: 'left' | 'right';
}

// Define the type here
const FeatureContainer = styled.div<StyledFeatureContainerProps>`
    display: flex;
    flex-direction: ${(props) => props.imagePosition === "right" ? "row" : "row-reverse"};
    margin-bottom: 20px;
    margin-top: 30px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ImageContainer = styled.div`
    margin: 20px;
    min-width: 300px;
    @media (max-width: 768px) {
        margin: 10px 0; // Reduce margin in mobile view
    }
`;

const LearnHow = styled.div`
    color: var(--mr-color-blurple);
    display: inline-flex;
    position: relative;
    font-weight: 700;
    cursor: pointer;
`;

const StyledArrowRight = styled(HiArrowRight)`
  margin-left: 10px;
`;

const TextContainer = styled.div`
    h2 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
        @media (max-width: 768px) {
            font-size: 1.5em; // Smaller font size for mobile
            max-width: 100%; // Allow text to take up more space
            text-align: center;
        }
    }

    p {
        color: #474747;
        font-weight: lighter;
        font-size: 1.1rem;
        margin-bottom: 20px;
        padding-top: 15px;
        line-height: 1.9;
        @media (max-width: 768px) {
            font-size: 1rem; // Slightly smaller font size for mobile
            padding-top: 10px;
            text-align: center; // Center text on mobile
        }
    }
`;
const FeaturesSectionComponent: React.FC<FeatureProps> = ({ title, description, image, imagePosition }) => {
    const { t } = useTranslation();
    return (
        <FeatureContainer imagePosition={imagePosition}>
            <ImageContainer>
                <img alt={title} src={image} height={300} style={{ maxWidth: '100%', height: 'auto' }} />
            </ImageContainer>
            <TextContainer>
                <h2>{title}</h2>
                <p>{description}</p>
                <LearnHow>{t('learnMore')}<StyledArrowRight /></LearnHow>
            </TextContainer>
        </FeatureContainer>
    );
};
export default FeaturesSectionComponent;
