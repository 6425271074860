import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;


export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }
`;

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 200px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;


interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ContentSide = styled.div`
    text-align: center;
    h4 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1.5rem;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h4 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    min-height: 800px;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;

const Content = styled.div`
   min-height: 2400px;
`;

function TermsOfServicesPage() {
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                    <ContentSide>
                        <h4>Terms and Conditions</h4>
                    </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesSection>
                <Content>
                    <i>Last updated: September 21, 2024</i>
                    <br/><br/>
                    Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
                    www.rocketdesert.com website (the “Platform” or “Rocket Desert Platform”) operated by Rocket Desert ("us", "we", or "our"). By accessing or using the Service, you agree to be bound by these Terms.
                    If you disagree with any part of the Terms, you may not access the Service.
                    <br/><br/>
                    <b>Beta Disclaimer</b>
                    <br/><br/>
                    The Rocket Desert Platform is currently in its beta phase. While we strive to provide a reliable and
                    accurate Service, there may be bugs, errors, or other issues that may affect the functionality and
                    performance of the Platform. By using the Service during this period, you acknowledge that the
                    Platform is provided "as is" and may not operate as intended.
                    <br/><br/>
                    <b>Use of Service</b>
                    <br/><br/>
                    The Rocket Desert Platform is a subscription-based SaaS service that enables businesses to manage
                    employee onboarding and offboarding processes. By using the Service, you agree to use the Platform
                    in compliance with applicable laws and regulations. Please note that we currently operate only in
                    Georgia, and the Service is intended for use within this jurisdiction.
                    <br/><br/>
                    <b>Prohibited Content</b>
                    <br/><br/>
                    Users are prohibited from sharing or uploading any content that is restricted or prohibited by
                    Georgian law. This includes, but is not limited to, content that is illegal, offensive, defamatory,
                    or infringes on intellectual property rights. We reserve the right to remove any content that
                    violates these Terms or applicable laws.
                    <br/><br/>
                    <b>User Accounts</b>
                    <br/><br/>
                    To access and use the Service, you must create an account by providing accurate and up-to-date
                    information. When registering a company, you must provide correct information and confirm that you
                    are an authorized representative of the company. You are responsible for safeguarding your account
                    details, including your password, and for any activities or actions under your account. If you
                    become aware of any breach of security or unauthorized use of your account, you must notify us
                    immediately.
                    <br/><br/>
                    <b>Data Usage and Fine-Tuning</b>
                    <br/><br/>
                    By using the Service, you acknowledge and agree that we may use the data and materials you upload to
                    fine-tune and improve our employee assessment processes. This is done to enhance the Service and
                    provide a better user experience. All data usage will be in compliance with our Privacy Policy and
                    applicable laws.
                    <br/><br/>
                    <b>Payment and Subscription</b>
                    <br/><br/>
                    Our Service is offered on a subscription basis, including a Free Tier that allows for up to 10
                    employees at no cost. By subscribing to the Service, you agree to pay all applicable fees, taxes,
                    and charges in accordance with our pricing structure, available on our Pricing Page. We reserve the
                    right to modify our subscription fees at any time, with prior notice to existing subscribers.
                    Subscription fees are non-refundable, and any unused portion of the subscription will not be
                    refunded upon cancellation.
                    <br/><br/>
                    <b>Cloud Data Hosting</b>
                    <br/><br/>
                    All data and information provided by users are securely stored and hosted on cloud servers. We
                    employ industry-standard security measures to protect your data. However, you acknowledge that no
                    method of electronic storage is completely secure, and we cannot guarantee absolute security.
                    <br/><br/>
                    <b>Termination</b>
                    <br/><br/>
                    We reserve the right to terminate or suspend your account and access to the Service at our sole
                    discretion, without notice or liability, including the right to terminate company accounts at any
                    time. Reasons for termination may include, but are not limited to, your breach of these Terms or any
                    applicable laws. Upon termination, your right to use the Service will cease immediately.
                    <br/><br/>
                    <b>Intellectual Property</b>
                    <br/><br/>
                    All intellectual property rights in the Platform, including but not limited to copyrights,
                    trademarks, patents, and trade secrets, belong to Rocket Desert or our licensors. You may not
                    copy, modify, distribute, or otherwise use any part of the Service without prior written consent
                    from us.
                    <br/><br/>
                    <b>Disclaimer of Warranties</b>
                    <br/><br/>
                    The Service is provided on an "as is" and "as available" basis. We make no warranties or
                    representations, express or implied, regarding the Service, including but not limited to warranties
                    of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that
                    the Service will be error-free, secure, or uninterrupted.
                    <br/><br/>
                    <b>Limitation of Liability</b>
                    <br/><br/>
                    To the fullest extent permitted by applicable law, Rocket Desert and its affiliates,
                    directors, employees, and agents will not be liable for any indirect, incidental, special, or
                    consequential damages, including but not limited to loss of profits, data, or business
                    opportunities, arising out of or related to your use or inability to use the Service.
                    <br/><br/>
                    <b>Indemnification</b>
                    <br/><br/>
                    You agree to indemnify and hold harmless Rocket Desert its affiliates, officers, directors,
                    employees, and agents from and against any claims, liabilities, damages, losses, or expenses,
                    including reasonable attorneys' fees, arising out of or in connection with your use of the Service
                    or your breach of these Terms.
                    <br/><br/>
                    <b>Governing Law</b>
                    <br/><br/>
                    These Terms are governed by and construed in accordance with the laws of Georgia, without regard to
                    its conflict of law principles. Any legal actions or proceedings arising out of or relating to these
                    Terms or the Service will be brought exclusively in the courts located within Georgia.
                    <br/><br/>
                    <b>Changes to Terms</b>
                    <br/><br/>
                    We reserve the right to modify these Terms at any time. If a change is material, we will notify you
                    at least 30 days prior to the new terms taking effect. Your continued use of the Service following
                    any changes will constitute your acceptance of the revised Terms.
                    <br/><br/>
                    <b>Contact Us</b>
                    <br/><br/>
                    If you have any questions about these Terms, please contact us at contact@rocketdesert.com.
                </Content>
            </FeaturesSection>
            <FooterComponent/>
        </Container>
    );

}

export default TermsOfServicesPage;
