import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;


export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }
`;

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 200px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;


interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ContentSide = styled.div`
    text-align: center;
    h4 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1.5rem;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h4 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    min-height: 800px;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;

const Content = styled.div`
   min-height: 2400px;
`;

function CookiePolicyPage() {
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                    <ContentSide>
                        <h4>Cookie Policy</h4>
                    </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesSection>
                <Content>
                    <i>Last updated: September 21, 2024</i>
                    <br/><br/>
                    Rocket Desert, Inc ("we", "our", or "us") uses cookies and similar tracking technologies on the
                    Rocket Desert Platform (the "Service") operated through www.rocketdesert.com. This Cookies Policy
                    explains what cookies are, how we use them, and your choices regarding their use.
                    <br/><br/>
                    By using the Service, you agree to the use of cookies in accordance with this policy. If you do not
                    agree, please disable cookies by adjusting your browser settings or refrain from using the Service.
                    <br/><br/>
                    1. What are Cookies?
                    <br/><br/>
                    Cookies are small text files placed on your device (computer, smartphone, tablet) when you visit a
                    website. They help websites recognize your device and store information about your preferences or
                    past actions.
                    <br/><br/>
                    2. How We Use Cookies
                    <br/><br/>
                    We use cookies and similar tracking technologies for various purposes:
                    <br/><br/>
                    Essential Cookies: These cookies are necessary for the proper functioning of the Service. They
                    enable core functionalities such as security, network management, and accessibility. Without these
                    cookies, certain features of the Service may not work correctly.
                    <br/><br/>
                    Performance and Analytics Cookies: These cookies collect information about how you use the Service,
                    such as pages visited and any error messages received. This helps us improve the performance and
                    user experience of our Platform.
                    <br/><br/>
                    Functionality Cookies: These cookies remember choices you make (such as your language or region) to
                    provide a more personalized experience.
                    <br/><br/>
                    Targeting or Advertising Cookies: While we currently do not display third-party advertisements,
                    these cookies may be used in the future to deliver relevant ads based on your interests.
                    <br/><br/>
                    3. Third-Party Cookies
                    <br/><br/>
                    We may use third-party service providers to analyze the use of our Service. These third parties may
                    set their own cookies on your device. For example:
                    <br/><br/>
                    Analytics Providers: We might use services like Google Analytics to collect and analyze usage
                    information, helping us understand how users engage with the Service.
                    4. Your Choices Regarding Cookies
                    <br/><br/>
                    You have the right to decide whether to accept or reject cookies:
                    <br/><br/>
                    Browser Settings: Most web browsers automatically accept cookies, but you can modify your browser
                    settings to decline cookies or alert you when a cookie is being sent. Please refer to your browser's
                    help section for instructions on how to manage cookies.
                    <br/><br/>
                    Opt-Out Links: For certain third-party cookies, you can opt out by visiting the third party's
                    website and following their instructions.
                    <br/><br/>
                    Please note that disabling or deleting cookies may affect the functionality of the Service, and
                    certain features may not be available to you.
                    <br/><br/>
                    5. Data Protection and Privacy
                    <br/><br/>
                    All data collected through cookies is governed by our Privacy Policy. We are committed to
                    safeguarding your personal information and using it only as outlined in our policies.
                    <br/><br/>
                    6. Changes to this Cookies Policy
                    <br/><br/>
                    We may update this Cookies Policy from time to time to reflect changes in our practices or for other
                    operational, legal, or regulatory reasons. When we make changes, we will update the "Last Updated"
                    date at the top of this policy. We encourage you to review this Cookies Policy periodically to stay
                    informed about our use of cookies.
                    <br/><br/>
                    7. Contact Us
                    <br/><br/>
                    If you have any questions or concerns about our use of cookies, please contact us at:
                    <br/><br/>
                    Email: contact@rocketdesert.com
                </Content>
            </FeaturesSection>
            <FooterComponent />
        </Container>
    );

}

export default CookiePolicyPage;
