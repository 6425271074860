import React from 'react';
import styled from "styled-components";
import FeaturesSectionComponent from "../components/features/features.component";
// @ts-ignore
import ImageFirst from "../../../assets/images/work-5947648_1280.png";
// @ts-ignore
import ImageSecond from "../../../assets/images/work-4997565_1280.png";
// @ts-ignore
import ImageThird from "../../../assets/images/work-6855849_1280.png";
import {useTranslation} from "react-i18next";
const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    border-top: #e8eaeb 1px solid;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;



function FeaturesComponent() {
    const { t } = useTranslation();
    return (<>
            <FeaturesSection>
                <FeaturesSectionComponent
                    title={t('employeeOnboarding')}
                    description={t('employeeOnboardingDescription')}
                    image={ImageFirst} imagePosition={'right'} />
                <FeaturesSectionComponent
                    title={t('employeeOffBoarding')}
                    description={t('employeeOffBoardingDescription')}
                    image={ImageSecond} imagePosition={'left'} />
                <FeaturesSectionComponent
                    title={t('employeeAssessment')}
                    description={t('employeeAssessmentDescription')}
                    image={ImageThird} imagePosition={'right'} />
            </FeaturesSection>
        </>
    );

}

export default FeaturesComponent;
