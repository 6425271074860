import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;


export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }
`;

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 200px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;


interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ContentSide = styled.div`
    text-align: center;
    h4 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1.5rem;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h4 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    min-height: 800px;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;

const Content = styled.div`
   min-height: 3300px;
`;

function PrivacyPolicyPage() {
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                    <ContentSide>
                        <h4>Privacy Policy</h4>
                    </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesSection>
                <Content>
                    <b>1. Information We Collect</b>
                    <br/><br/>
                    We may collect various types of information depending on how you interact with our Service:
                    <br/><br/>
                    Personal Information: When you create an account, register a company, or subscribe to our Service,
                    we collect personal information such as your name, email address, phone number, company details, and
                    payment information. You must provide accurate information and confirm that you are an authorized
                    representative of the company.
                    <br/><br/>
                    Uploaded Content: Any data, materials, or content you upload to the Service, including employee
                    information necessary for onboarding and offboarding processes. Please ensure that the content you
                    upload complies with Georgian law and does not include prohibited or restricted material.
                    <br/><br/>
                    Usage Data: Information about how you access and use the Service, such as IP addresses, browser
                    type, device information, pages visited, links clicked, and other diagnostic data.
                    <br/><br/>
                    Communications: Any messages, feedback, or other communications you send to us.
                    <br/><br/>
                    Log Data: Our servers automatically collect information when you use the Service.
                    <br/><br/>
                    <b>2. How We Use Your Information</b>
                    <br/><br/>
                    We use the collected information for the following purposes:
                    <br/><br/>
                    To Provide and Improve the Service: Operating, maintaining, and enhancing the functionality of our
                    Platform, including employee onboarding and offboarding processes.
                    <br/><br/>
                    Verification: Confirming that you are an authorized representative of the company you register.
                    <br/><br/>
                    Data Fine-Tuning and Employee Assessment: Utilizing the data and materials you upload to fine-tune
                    and improve our employee assessment processes, thereby enhancing the Service and user experience.
                    <br/><br/>
                    Payment Processing: Managing subscriptions, including processing payments and billing.
                    <br/><br/>
                    Communication: Sending important updates, responding to inquiries, providing customer support, and
                    notifying you about changes to the Service.
                    <br/><br/>
                    Legal Compliance: Ensuring compliance with Georgian laws and regulations.
                    <br/><br/>
                    <b>3. Data Sharing and Disclosure</b>
                    <br/><br/>
                    We do not sell your personal information to third parties. However, we may share your data under the
                    following circumstances:
                    <br/><br/>
                    Service Providers: With third-party vendors who assist in operating our business and Service (e.g.,
                    cloud hosting providers, payment processors). All data is hosted on secure cloud servers employing
                    industry-standard security measures.
                    <br/><br/>
                    Legal Obligations: If required to comply with applicable laws, regulations, legal processes, or
                    governmental requests within Georgia.
                    <br/><br/>
                    Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition
                    of all or a portion of our business to another company.
                    <br/><br/>
                    <b>4. Data Security</b>
                    <br/><br/>
                    We take reasonable measures to protect your personal information from unauthorized access,
                    alteration, disclosure, or destruction. However, no method of transmission over the Internet or
                    electronic storage is completely secure, and we cannot guarantee absolute security.
                    <br/><br/>
                    <b>5. Your Data Rights</b>
                    <br/><br/>
                    Under Georgian law, you may have the following rights regarding your personal data:
                    <br/><br/>
                    Access: Request access to the personal information we hold about you.
                    <br/><br/>
                    Correction: Request correction of inaccurate or incomplete information.
                    <br/><br/>
                    Deletion: Request deletion of your personal data under certain conditions.
                    <br/><br/>
                    Objection: Object to the processing of your personal data in certain circumstances.
                    <br/><br/>
                    Withdrawal of Consent: Withdraw your consent where we are relying on it to process your personal
                    data.
                    <br/><br/>
                    To exercise these rights, please contact us at contact@rocketdesert.com.
                    <br/><br/>
                    <b>6. Data Retention</b>
                    <br/><br/>
                    We retain your personal information for as long as your account is active or as necessary to provide
                    you with the Service. We may also retain and use your information to comply with legal obligations,
                    resolve disputes, enforce our agreements, or as otherwise required by law.
                    <br/><br/>
                    <b>7. Cookies and Tracking Technologies</b>
                    <br/><br/>
                    We use cookies and similar tracking technologies to enhance your experience, analyze usage, and
                    deliver personalized content. You can manage your cookie preferences through your browser settings.
                    Disabling cookies may affect the functionality of the Service.
                    <br/><br/>
                    <b>8. Prohibited Content</b>
                    <br/><br/>
                    Users are prohibited from sharing or uploading any content that is restricted or prohibited by
                    Georgian law. This includes, but is not limited to, illegal, offensive, defamatory, or infringing
                    content. We reserve the right to remove any content that violates these Terms or applicable laws.
                    <br/><br/>
                    <b>9. Children's Privacy</b>
                    <br/><br/>
                    Our Service is not intended for individuals under the age of 18. We do not knowingly collect
                    personal information from children. If we become aware that a child under 18 has provided us with
                    personal information, we will take steps to delete such information promptly.
                    <br/><br/>
                    10. International Data Transfers
                    <br/><br/>
                    Since we currently operate only in Georgia, your data is processed and stored within Georgia. If you
                    access the Service from outside Georgia, please be aware that your information may be transferred
                    to, stored, and processed in Georgia.
                    <br/><br/>
                    <b>11. Changes to this Privacy Policy</b>
                    <br/><br/>
                    We reserve the right to modify this Privacy Policy at any time. If we make material changes, we will
                    notify you at least 30 days before the changes take effect. Your continued use of the Service after
                    any changes indicates your acceptance of the updated Privacy Policy.
                    <br/><br/>
                    <b>12. Termination</b>
                    <br/><br/>
                    We reserve the right to terminate or suspend your account at any time, including company accounts,
                    in accordance with our Terms and Conditions. Upon termination, your right to use the Service will
                    cease immediately.
                    <br/><br/>
                    <b>13. Free Tier and Data Usage</b>
                    <br/><br/>
                    Our Service includes a Free Tier allowing up to 10 employees at no cost. While using the Free Tier
                    or any paid subscription, your data may be used to fine-tune and improve our employee assessment
                    processes, enhancing the overall Service.
                    <br/><br/>
                    <b>14. Contact Us</b>
                    <br/><br/>
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                    <br/><br/>
                    Email: contact@rocketdesert.com
                </Content>
            </FeaturesSection>
            <FooterComponent />
        </Container>
    );

}

export default PrivacyPolicyPage;
