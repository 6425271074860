import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;


export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }
`;

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 200px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;


interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ContentSide = styled.div`
    text-align: center;
    h4 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1.5rem;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h4 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    min-height: 800px;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;

function MediaPage() {
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                    <ContentSide>
                        <h4>Media</h4>
                    </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesSection>
            </FeaturesSection>
            <FooterComponent />
        </Container>
    );

}

export default MediaPage;
