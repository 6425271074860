import React from 'react';
import styled from "styled-components";
import { FaCheck } from "react-icons/fa6";
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;


const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 200px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;


interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;


const ContentSide = styled.div`
    text-align: center;
    h4 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.1;
        margin: 5px;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1rem;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h4 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeaturesSection = styled.div`
    display: flex;
    max-width: 1400px;
    width: 100%;
    min-height: 600px;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;

const PlanItem = styled.div`
    display: flex;
    width: 400px;
    flex-direction: column;
    border: 1px solid #9ea1a3;
    border-radius: 10px;
    min-height: 500px;
    padding: 20px;
    margin-right: 20px;
`;
const PlanName = styled.div`
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 15px;
`;
const PlanDescription = styled.div`
    font-weight: lighter;
`;
const PlanPrice = styled.div`
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const PriceWrapper = styled.div`
    display: flex;
`;

const PerMonth = styled.div`
    margin-top: 57px;
    margin-left: 10px;
`;

const TalkToUs = styled.div`
    width: 380px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #1c4396;
    color: white;
    align-items: center;
    padding: 10px;
    text-align: center;
`;
const FeaturesList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;
const FeatureItem = styled.div`
    font-size: 1rem;
    padding-bottom: 10px;
`;

const GreenIcon = styled(FaCheck)`
    color: green;
    padding-right: 5px;
    padding-top: 5px;
`;

function PricingPage() {
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                    <ContentSide>
                        <h4>Plans for teams of all sizes</h4>
                        <p>Get started in minutes with our <b>free plan</b></p>
                    </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesSection>
                <PlanItem>
                    <PlanName>Free</PlanName>
                    <PlanDescription>Experience full power of the platform on a smaller scale</PlanDescription>
                    <PlanPrice>$0</PlanPrice>
                    <TalkToUs>Talk to us</TalkToUs>
                    <FeaturesList>
                        <FeatureItem><GreenIcon />Up to 10 employee</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Onboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Offboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Teams</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Chapters</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Workflows</FeatureItem>
                    </FeaturesList>
                </PlanItem>
                <PlanItem>
                    <PlanName>Teams</PlanName>
                    <PlanDescription>Experience full power of the platform on a smaller scale</PlanDescription>
                    <PriceWrapper>
                        <PlanPrice>
                            $5
                        </PlanPrice>
                        <PerMonth>employee per month</PerMonth>
                    </PriceWrapper>
                    <TalkToUs>Talk to us</TalkToUs>
                    <FeaturesList>
                        <FeatureItem><GreenIcon />Unlimited Onboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Offboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Teams</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Chapters</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Workflows</FeatureItem>
                        <FeatureItem><GreenIcon />Data Migration</FeatureItem>
                        <FeatureItem><GreenIcon />API</FeatureItem>
                    </FeaturesList>
                </PlanItem>
                <PlanItem>
                    <PlanName>Enterprise</PlanName>
                    <PlanDescription>Experience full power of the platform on a smaller scale</PlanDescription>
                    <PlanPrice>Contact Us</PlanPrice>
                    <TalkToUs>Talk to us</TalkToUs>
                    <FeaturesList>
                        <FeatureItem><GreenIcon />Unlimited Onboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Offboardings</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Teams</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Chapters</FeatureItem>
                        <FeatureItem><GreenIcon />Unlimited Workflows</FeatureItem>
                        <FeatureItem><GreenIcon />Data Migration</FeatureItem>
                        <FeatureItem><GreenIcon />On-premise</FeatureItem>
                        <FeatureItem><GreenIcon />API</FeatureItem>
                        <FeatureItem><GreenIcon />SSO</FeatureItem>
                        <FeatureItem><GreenIcon />Training</FeatureItem>
                        <FeatureItem><GreenIcon />27/7 Support</FeatureItem>
                    </FeaturesList>
                </PlanItem>
            </FeaturesSection>
            <FooterComponent />
        </Container>
    );

}

export default PricingPage;
