import React, { useState } from 'react';
import styled from 'styled-components';
import { SlPlus, SlMinus } from 'react-icons/sl';
import {useTranslation} from "react-i18next";

type FaqItem = {
    question: string;
    answer: string;
};

const Container = styled.div`
    font-family: Arial, sans-serif;
    width: 1000px;
    margin: 0 auto;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

const QuestionContainer = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;
`;

const Question = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background-color: #f8f8f8;
    color: #303030;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.9px;
`;

const AnswerContainer = styled.div<{isOpen: boolean}>`
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: ${({isOpen}) => (isOpen ? "150px" : "0px")};
`;

const Answer = styled.div<{isOpen: boolean}>`
    padding: 20px;
    border-top: none;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    transform: ${({isOpen}) => (isOpen ? "scaleY(1)" : "scaleY(0)")};
    opacity: ${({isOpen}) => (isOpen ? "1" : "0")};
    transform-origin: top;
    color: #474747;
    font-weight: lighter;
    font-size: 1.1rem;
    padding-top: 15px;
    line-height: 1.9;
    font-family: sans-serif;
`;

const FAQItem: React.FC<FaqItem> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <QuestionContainer onClick={toggle}>
            <Question>
                {question}
                {isOpen ? <SlMinus size={20} /> : <SlPlus size={20} />}
            </Question>
            <AnswerContainer isOpen={isOpen}>
                <Answer isOpen={isOpen}>{answer}</Answer>
            </AnswerContainer>
        </QuestionContainer>
    );
};

const FAQ: React.FC = () => {
    const { t } = useTranslation();
    const faqs: FaqItem[] = [
        { question: t('question1'), answer: t('answer1') },
        { question: t('question2'), answer: t('answer2')},
        { question: t('question3'), answer: t('answer3') },
        { question: t('question4'), answer: t('answer4') },
        // Add other questions and answers here
    ];

    return (
        <Container>
            {faqs.map((faq) => (
                <FAQItem key={faq.question} {...faq} />
            ))}
        </Container>
    );
};

export default FAQ;