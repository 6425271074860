import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import LanguageSelector from "../components/localization/localization.component";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: -1.5px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    @media (max-width: 767px) {
       font-size: 44px;
    }
    cursor: pointer;
`;
styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;

const Footer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    min-height: 200px;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: #333;
    @media (max-width: 767px) {
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
`;

const FooterLogoSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FooterLogo = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
`;

const SocialMedia = styled.div`
    display: flex;
    gap: 10px;
    font-size: 12px;
    width: 200px;
    color: #46494b;
`;


const FooterContainer = styled.div`
    display: flex;
    padding: 20px;
    width: 100%;
    max-width: 1400px;
    color: #474747;
    font-weight: lighter;
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.9;
    @media (max-width: 767px) {
        flex-direction: column;
        padding: 0;
        margin-top: 20px;
        text-align: center;
    }
`;

const ProductsList = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 50px;
    padding-left: 50px;
    @media (max-width: 767px) {
        font-size: 1.5rem;
    }
`;

const AboutCompany = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 50px;
    padding-left: 50px;
    @media (max-width: 767px) {
        font-size: 1.4rem;
    }
`;

const LegalList = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 50px;
    padding-left: 50px;
    @media (max-width: 767px) {
        font-size: 1.4rem;
    }
`;

const FooterItem = styled.a`
    color: #333;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: blue;
    }
`;

function FooterComponent() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    return (
        <Footer>
            <FooterLogoSide>
                <FooterLogo>
                    <Logo>Rocket Desert</Logo>
                </FooterLogo>
                <SocialMedia>
                    &copy; 2024 Rocket Desert Inc.
                </SocialMedia>
            </FooterLogoSide>
            <FooterContainer>
                <ProductsList>
                    <FooterItem onClick={() => navigate('/onboarding') }>{t('onboarding')}</FooterItem>
                    <FooterItem onClick={() => navigate('/offboarding') }>{t('offBoarding')}</FooterItem>
                    <FooterItem onClick={() => navigate('/assessment') }>{t('assessments')}</FooterItem>
                </ProductsList>
                <AboutCompany>
                    <FooterItem onClick={() => navigate('/about-company') }>{t('aboutCompany')}</FooterItem>
                    <FooterItem onClick={() => navigate('/news') }>{t('news')}</FooterItem>
                    <FooterItem onClick={() => navigate('/media') }>{t('media')}</FooterItem>
                </AboutCompany>
                <LegalList>
                    <FooterItem onClick={() => navigate('/terms-of-service') }>{t('termsOfService')}</FooterItem>
                    <FooterItem onClick={() => navigate('/privacy-policy') }>{t('privacyPolicy')}</FooterItem>
                    <FooterItem onClick={() => navigate('/cookies-policy') }>{t('cookiesPolicy')}</FooterItem>
                </LegalList>
                <LegalList>
                    <LanguageSelector showText={true} />
                </LegalList>
            </FooterContainer>
        </Footer>
    );

}

export default FooterComponent;
