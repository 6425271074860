import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./pages/landing/landing.page";
import './i18n';
import CompanyPage from "./pages/landing/about.page";
import PricingPage from "./pages/landing/pricing.page";
import UseCasesPage from "./pages/landing/use-cases.page";
import ProductsPage from "./pages/landing/products.page";
import NewsPage from "./pages/landing/news.page";
import MediaPage from "./pages/landing/media.page";
import ApolonPage from "./pages/landing/apolon.page";
import AssessmentPage from "./pages/landing/assessment.page";
import TermsOfServicesPage from "./pages/landing/terms-of-services.page";
import PrivacyPolicyPage from "./pages/landing/privacy-policy.page";
import CookiesPolicyPage from "./pages/landing/cookies-policy.page";
import SecurityPage from "./pages/landing/security.page";
import OnboardingPage from "./pages/landing/onboarding.page";
import OffBoardingPage from "./pages/landing/offboarding.page"; // Import i18n configuration
import  './style.css';
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />
  },
  {
    path: "/about-company",
    element: <CompanyPage />
  },
  {
    path: "/apolon-pachulia",
    element: <ApolonPage />
  },
  {
    path: "/onboarding",
    element: <OnboardingPage />
  },
  {
    path: "/offboarding",
    element: <OffBoardingPage />
  },
  {
    path: "/assessment",
    element: <AssessmentPage />
  },
  {
    path: "/pricing",
    element: <PricingPage />
  },
  {
    path: "/use-cases",
    element: <UseCasesPage />
  },
  {
    path: "/products",
    element: <ProductsPage />
  },
  {
    path: "/news",
    element: <NewsPage />
  },
  {
    path: "/terms-of-service",
    element: <TermsOfServicesPage />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />
  },
  {
    path: "/cookies-policy",
    element: <CookiesPolicyPage />
  },
  {
    path: "/security",
    element: <SecurityPage />
  },
  {
    path: "/media",
    element: <MediaPage />
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();