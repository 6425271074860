import React, { useState, useEffect } from 'react';
import { SlGlobe } from 'react-icons/sl';
import i18n from '../../../../i18n';
import './styles.css';

interface Language {
    code: string;
    name: string;
}

interface LanguageSelectorProps {
    showText?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ showText = true }) => {
    const getInitialLanguage = () => window.localStorage.getItem('language') || 'en';

    const [language, setLanguage] = useState<string>(getInitialLanguage);
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const languages: Language[] = [
        { code: 'en', name: 'English' },
        { code: 'fr', name: 'Français' },
        // { code: 'sv', name: 'Svenska' },
        // { code: 'uk', name: 'Українська' },
        // { code: 'it', name: 'Italiano' },
        // { code: 'pl', name: 'Polski' },
        // { code: 'pt', name: 'Português' },
        // { code: 'fi', name: 'Suomi' },
        // { code: 'ru', name: 'Русский' },
        // { code: 'he', name: 'עברית' },
        // { code: 'zh', name: '简体中文' },
        // { code: 'ja', name: '日本語' },
        // { code: 'ko', name: '한국어' },
        // { code: 'hi', name: 'हिन्दी' }
    ];

    const handleLanguageChange = (code: string) => {
        setLanguage(code);
        window.localStorage.setItem('language', code);

        if (code === 'he') {
            document.documentElement.setAttribute('dir', 'rtl');
        } else {
            document.documentElement.setAttribute('dir', 'ltr');
        }

        i18n.changeLanguage(code);
        setShowOptions(false);
    };

    useEffect(() => {
        const localLanguage = window.localStorage.getItem('language');

        if (localLanguage) {
            i18n.changeLanguage(localLanguage);
        }
    }, []);

    return (
        <div className={`language-selector ${showText ? "" : "no-text"}`}>
            <button
                className={`language-button ${showText ? "" : "no-text"}`}
                onClick={() => setShowOptions(!showOptions)}
            >
                <SlGlobe size={24} />
                {showText && <span>{languages.find(lang => lang.code === language)?.name}</span>}
            </button>
            {showOptions && (
                <ul className="language-options">
                    {languages.map(lang => (
                        <li key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
                            {lang.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguageSelector;