import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const NewsCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-4px);
  }
`;

const NewsImageContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`;

const NewsImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NewsContent = styled.div`
  padding: 1.5rem;
`;

const NewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #666;
`;

const NewsTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #333;
`;

const NewsDescription = styled.p`
  color: #666;
  line-height: 1.5;
`;

const NewsComponent: React.FC = () => {
    return (
        <Container>
            <NewsGrid>
                <NewsCard>
                    <NewsImageContainer>
                        <NewsImage src={require('../../../../assets/images/img_2.png')} alt={'aws startups'} />
                    </NewsImageContainer>
                    <NewsContent>
                        <NewsHeader>
                            <span>Tbilisi, Georgia</span>
                            <span>3-4 December 2024</span>
                        </NewsHeader>
                        <NewsTitle>Touch24 Summit</NewsTitle>
                        <NewsDescription>Rocketdesert.com will be showcased at the Touch24 Summit, where we’ll introduce new AI features and our latest product.</NewsDescription>
                    </NewsContent>
                </NewsCard>
                <NewsCard>
                    <NewsImageContainer>
                        <NewsImage src={require('../../../../assets/images/vivatech.jpeg')} alt={'aws startups'} />
                    </NewsImageContainer>
                    <NewsContent>
                        <NewsHeader>
                            <span>Paris, France</span>
                            <span>15 May 2024</span>
                        </NewsHeader>
                        <NewsTitle>Rocketdesert.com at VivaTech: Europe’s Biggest Startup Event</NewsTitle>
                        <NewsDescription>We attended the largest startup event in Europe, VivaTech, in Paris, France.</NewsDescription>
                    </NewsContent>
                </NewsCard>
                <NewsCard>
                    <NewsImageContainer>
                        <NewsImage src={require('../../../../assets/images/img.png')} alt={'aws startups'} />
                    </NewsImageContainer>
                    <NewsContent>
                        <NewsHeader>
                            <span>Tbilisi, Georgia</span>
                            <span>1 February 2024</span>
                        </NewsHeader>
                        <NewsTitle>Rocketdesert.com Joins AWS Startup Program</NewsTitle>
                        <NewsDescription>Rocketdesert.com was selected as an AWS startup, which will help us develop AI features.</NewsDescription>
                    </NewsContent>
                </NewsCard>
            </NewsGrid>
        </Container>
    );
};

export default NewsComponent;