import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import LanguageSelector from "../components/localization/localization.component";

const Banner = styled.div`
    background-color: #3e4ae7;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    position: relative;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

const Header = styled.div`
    border-bottom: #e8eaeb 1px solid;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ContainerWrapper = styled.div`
    height: 100px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures vertical center alignment of items */
    @media (max-width: 767px) {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    font-family: monospace;
    @media (max-width: 767px) {
        font-size: 44px;
    }
    cursor: pointer;
    letter-spacing: -2.5px;
    font-synthesis: initial;
    font-optical-sizing: unset;
    font-weight: bold;
`;

const ListContainer = styled.ul`
    list-style: none;
    display: flex;
    justify-content: left;
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;

const MenuDescription = styled.div`

`;

export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }

        // ${MenuDescription} {
    //     width: 100vw;
    //     height: auto;  // Adjust the height
    //     display: none;
    //     position: absolute;
    //     background-color: #FFFFFF;
    //     min-width: 160px;
    //     padding: 12px 30px;
    //     border: 1px solid #e8eaeb;
    //     border-top: none;
    //     z-index: 1;
    //     left: 0;
    // }
`;

const LoginContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    @media (max-width: 767px) {
        display: none;
    }
`;

const LoginButton = styled.button`
    color:  #333;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    background-color: #FFFFFF;
    font-weight: 500;
`;

interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const LeftSideContainer = styled.div`
    display: flex;
`;

const MenuContainer = styled.div`
    padding-left: 20px;
    @media (max-width: 767px) {
        display: none;
    }
`;


function HeaderComponent() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    return (
            <Header>
                <Banner>{t('launchMessage')}</Banner>
                <ContainerWrapper>
                    <LeftSideContainer>
                        <Logo onClick={() => navigate('/') }>Rocket Desert</Logo>
                        <MenuContainer>
                            <ListContainer>
                                <Item onClick={() => navigate('/products') }>
                                    {t('products')}
                                </Item>
                                <Item onClick={() => navigate('/use-cases') }>
                                    {t('useCases')}
                                </Item>
                                <Item onClick={() => navigate('/pricing') }>{t('pricing')}</Item>
                                <Item onClick={() => navigate('/about-company') }>
                                    {t('company')}
                                </Item>
                            </ListContainer>
                        </MenuContainer>
                    </LeftSideContainer>
                    <LoginContainer>
                        <LanguageSelector showText={false} />
                        <LoginButton onClick={() => window.open('https://beta-dashboard.rocketdesert.com', '_blank')}>{t('signIn')}</LoginButton>
                        <GenericButton backgroundColor='#cfeb34' fontColor='#333' onClick={() => window.open('https://calendly.com/contact-wpsv/30min?month=2024-05', '_blank')}>
                            {t('requestDemo')}
                        </GenericButton>
                    </LoginContainer>
                </ContainerWrapper>
            </Header>
    );

}

export default HeaderComponent;
