import React from 'react';
import styled from "styled-components";
import FeaturesSectionComponent from "../components/features/features.component";
// @ts-ignore
import StartupImage from "../../../assets/images/startup.jpg";
// @ts-ignore
import EnterpriseImage from "../../../assets/images/enterprise.jpg";
// @ts-ignore
import FooterComponent from "./footer.component";
const FeaturesSection = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    border-top: #e8eaeb 1px solid;
    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 30px;
    }
`;



function UseCasesComponent() {
    return (<>
            <FeaturesSection>
                <FeaturesSectionComponent
                    title={'Startups use case'}
                    description={'Our product addresses the onboarding and off-boarding challenges faced by startups. By leveraging AI, we automate these processes, making them efficient and enjoyable. Our platform scales globally, supporting diverse onboarding needs with features like benefits management, continuous onboarding, knowledge sharing, learning and development, and an AI Assistant trained on company-specific data. These AI-driven solutions enhance knowledge tracking and sharing, ensuring new hires quickly integrate and access crucial information. This approach fosters continuous improvement and agility, vital for startups. Overall, our solution saves time, reduces errors, and enhances the employee experience, driving startup success.'}
                    image={StartupImage} imagePosition={'right'} />
                <FeaturesSectionComponent
                    title={'Enterprises use case'}
                    description={'Our product tackles the complex onboarding and off-boarding processes faced by enterprises. Using AI, we automate these procedures, enhancing efficiency and employee satisfaction. Our platform scales globally, catering to diverse enterprise needs with features like benefits management, continuous onboarding, knowledge sharing, learning and development, and an AI Assistant trained on company-specific data. These AI-driven solutions streamline knowledge tracking and sharing, ensuring smooth integration and access to vital information for new hires. This approach promotes continuous improvement and agility, crucial for large organizations. Ultimately, our solution saves time, reduces errors, and elevates the employee experience, supporting enterprise growth and success.'}
                    image={EnterpriseImage} imagePosition={'right'} />
            </FeaturesSection>
            <FooterComponent/>
        </>
    );

}

export default UseCasesComponent;
